<template>
  <div class="animated" v-if="patron">
    <b-card>
      <div slot="header"><i class="icon-link"></i> Voice role linking</div>
      <b-button variant="success" v-on:click="open_create">
        <font-awesome-icon icon="plus" style="margin-right:5px" />Create new
        voice link
      </b-button>
      <hr />
      <v-client-table
        :columns="columns"
        :data="data"
        :options="options"
        :theme="theme"
        id="dataTable"
        style="width: 100%; margin: none"
      >
        <p
          slot="channel"
          class="d-md-inline-block"
          slot-scope="props"
          style="max-width: 780px !important"
          block
        >
          {{ channel_from_id(props.row.channel_id).name }}
        </p>
        <p
          slot="roles"
          class="d-md-inline-block"
          slot-scope="props"
          style="max-width: 780px !important"
          block
        >
          {{ props.row.roles.map(x => role_from_id(x).name).join(", ") }}
        </p>
        <b-container
          slot="actions"
          class="d-md-inline-block"
          slot-scope="props"
          style="max-width: 780px !important"
          fluid
        >
          <b-row>
            <b-col>
              <b-button
                block
                variant="primary"
                v-on:click="open_edit(props.row)"
                >Edit</b-button
              >
            </b-col>
            <b-col>
              <b-button
                block
                variant="outline-danger"
                v-on:click="delete_link(props.row)"
              >
                Delete
                <i class="icon-trash"></i>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </v-client-table>
    </b-card>

    <b-modal
      size="lg"
      v-model="modal"
      @ok="modal = false"
      :no-enforce-focus="true"
    >
      <template slot="modal-title">
        <h4 class="smalltitle">
          <i class="icon-link" style="margin-right:10px"></i>Manage Voice Link
        </h4>
      </template>
      <b-form-group>
        <b-form-group>
          <label for="type_select" class="teenytinytitle">Channel</label>
          <multiselect
            v-model="current_channel"
            id="server_channel_select"
            track-by="id"
            label="name"
            placeholder="Please select a channel"
            :clear-on-select="false"
            :close-on-select="true"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="false"
            :options="channels"
            :allow-empty="false"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <font-awesome-icon style="color:#72767d" icon="hashtag" />
                <span class="option__title ml-2">{{ props.option.name }}</span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">
                  <font-awesome-icon style="color:#72767d" icon="hashtag" />
                  {{ props.option.name }}
                </span>
              </div>
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group>
          <label for="current_role" class="teenytinytitle"
            >Roles to assign</label
          >
          <multiselect
            v-model="current_roles"
            :options="roles"
            :multiple="true"
            track-by="id"
            id="current_roles"
          >
            <template slot="tag" slot-scope="{ option, search, remove }">
              <span
                class="multiselect__tag"
                :style="{
                  'border-color': option
                    ? '#' + option.color.toString(16)
                    : '#eeaaee'
                }"
              >
                <i
                  aria-hidden="true"
                  tabindex="1"
                  @keydown.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon fakediscord"
                  :style="{
                    'background-color': '#' + intToHex(option.color || 12172222)
                  }"
                ></i>
                <span class="defaultcursor" v-text="option.name"></span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div
                :style="{ color: parseColor(props.option) }"
                class="option__desc"
              >
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
        </b-form-group>
      </b-form-group>
      <div slot="modal-footer" class="w-100">
        <b-container
          fluid
          class="text-light text-center"
          style="padding-right:0px;"
        >
          <b-row class="mr-auto">
            <b-col cols="auto" class="mr-auto p-2"></b-col>
            <b-col cols="auto" class="p-2">
              <b-button variant="ghost-secondary" v-on:click="modal = false"
                >Cancel</b-button
              >
            </b-col>
            <b-col cols="auto" class="p-2">
              <b-button variant="primary" v-on:click="put_voice()"
                >Save</b-button
              >
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
  <div v-else>
    <PatronCard :patron="patron" :add_premium="add_premium" />
  </div>
</template>

<script>
import Vue from "vue";
import { ClientTable, Event } from "vue-tables-2";
import Multiselect from "vue-multiselect";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import PatronCard from "@/views/oc/PatronCard";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};

miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const toast_options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};
Vue.use(VueNotifications, toast_options);

Vue.use(ClientTable);

export default {
  name: "Voicelink",
  components: {
    ClientTable,
    Event,
    Multiselect,
    PatronCard
  },
  notifications: {
    bad_input: {
      type: VueNotifications.types.warn,
      title: "Uh oh",
      message: "Please fill in all fields"
    }
  },
  data: function() {
    return {
      columns: ["channel", "roles", "actions"],
      data: [],
      patron: true,
      modal: false,
      roles: [],
      channels: [],
      current_channel: null,
      current_roles: [],
      options: {
        filterByColumn: true,
        texts: {
          filter: "Filter",
          filterBy: "Filter by {column}"
        },
        sortable: ["channel", "roles", "actions"],
        filterable: ["channel", "roles"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: true,
          nav: "scroll"
        },
        childRow: false,
        highlightMatches: true
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default"
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/voicelink")
      .then(r => {
        this.data = r.data;
      });
  },
  methods: {
    role_from_id(id) {
      return this.roles.find(x => x.id == id) || { name: "unknown role" };
    },
    channel_from_id(id) {
      return (
        this.channels.find(x => x.id == id) || {
          name: "unknown channel (deleted?)"
        }
      );
    },
    parseColor(r) {
      return r.color != null ? "#" + r.color.toString(16) : "#fefefe";
    },
    intToHex(int) {
      if (!int) {
        return "eee";
      }
      return int.toString(16);
    },
    open_create() {
      this.current_channel = null;
      this.current_roles = [];
      this.modal = true;
    },
    open_edit(row) {
      this.current_channel = this.channel_from_id(row.channel_id);
      this.current_roles = row.roles.map(x => this.role_from_id(x));
      this.modal = true;
    },
    add_premium() {
      return this.axios
        .post("/api/v1/servers/" + this.$route.params.guild_id + "/patron", {})
        .then(r => {
          this.patron = true;
        });
    },
    put_voice() {
      if (!this.current_channel || !this.current_roles) {
        return this.bad_input();
      }

      return this.axios
        .put("/api/v1/servers/" + this.$route.params.guild_id + "/voicelink", {
          channel_id: this.current_channel.id,
          roles: this.current_roles.map(x => x.id)
        })
        .then(res => {
          this.modal = false;
          var found = false;
          this.data.forEach(e => {
            if (e.channel_id === this.current_channel.id) {
              e.roles = this.current_roles.map(x => x.id);
              found = true;
            }
          });
          if (!found) {
            this.data.push({
              channel_id: this.current_channel.id,
              roles: this.current_roles.map(x => x.id)
            });
          }
        });
    },
    delete_link(row) {
      return this.axios
        .delete(
          "/api/v1/servers/" +
            this.$route.params.guild_id +
            "/voicelink/" +
            row.channel_id
        )
        .then(resp => {
          this.data.forEach((x, i) => {
            if (x.channel_id === row.channel_id) {
              this.data.splice(i, 1);
            }
          });
        });
    }
  },
  created: function() {
    this.$Progress.start();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/voicelink")
      .then(r => {
        this.data = r.data.links;
        this.channels = r.data.channels;
        this.roles = r.data.roles;
        this.patron = r.data.is_patron;
        this.$Progress.finish();
      });
  }
};
</script>

<style lang="scss">
#dataTable {
  width: 95%;
  margin: 0 auto;

  .VuePagination {
    text-align: center;
    justify-content: center;
  }

  .td {
    word-wrap: !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .vue-title {
    text-align: center;
    margin-bottom: 10px;
  }

  .VueTables__search-field {
    display: flex;
  }
  .VueTables__search-field input {
    margin-left: 0.25rem;
  }

  .VueTables__limit-field {
    display: flex;
  }

  .VueTables__limit-field select {
    margin-left: 0.25rem !important;
  }

  .VueTables__table th {
    text-align: center;
  }

  .VueTables__child-row-toggler {
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: block;
    margin: auto;
    text-align: center;
  }

  .VueTables__child-row-toggler--closed::before {
    content: "+";
  }

  .VueTables__child-row-toggler--open::before {
    content: "-";
  }
}
</style>
